<template>
    <div class="pathology">
        <div class="title_box">
            <p class="title3">{{title}}</p>
            <p class="title_tip3">FORENSIC PATHOLOGY IDENTIFICATION</p>
        </div>
        <div class="space_content">
            法医病理鉴定是指鉴定人运用法医病理学的科学技术或者专门知识，对与法律问题有关的人身伤、残、病、死及死后变化等专门性问题进行鉴别和判断并提供鉴定意见的活动。
        </div>
        <div class="space_content">
法医病理鉴定包括死亡原因鉴定，死亡方式判断，死亡时间推断，损伤时间推断，致伤物推断，成伤机制分析，医疗损害鉴定以及与死亡原因相关的其他法医病理鉴定等
        </div>
        <img class="img" style="width:100%" v-lazy="require('../../assets/range/1.jpg')">
        <div class="pathology_title">
            死亡原因鉴定
        </div>
        <div class="space_content" style="text-indent:0rem;">
            基于具体案件鉴定中的检材情况、委托人的要求以及死者的民族习惯等，按照所采用的检查方法进行死亡原因鉴定或分析。死亡原因鉴定通常有以下类型：
        </div>
        <div class="space_content">尸体解剖，死亡原因鉴定。通过进行系统尸体解剖检验（包括但不限于颅腔、胸腔、腹腔等）；提取病理检材，对各器官进行大体检验和显微组织病理学检验；提取尸体相关体液或组织进行毒、药物检验，或者其他实验室检验（必要时）。根据上述尸体解剖检验和必要的实验室检验结果，结合案情资料及其他书证材料，对死亡原因等进行鉴定。</div>
        <div class="space_content">尸表检验，死亡原因分析。通过对尸体衣着、体表进行检验，必要时进行尸体影像学检查或提取相关体液检材进行毒、药物检验等。根据上述检验结果，并结合案情资料等对死亡原因等进行分析。</div>
        <div class="space_content" style="margin-bottom:2rem;">器官/切片检验，死亡原因分析。因鉴定条件所限，缺少尸体材料时（如：再次鉴定时尸体已处理），可以通过对送检器官/组织切片进行法医病理学检验与诊断，并结合尸体检验记录和照片、毒物检验结果以及案情资料、书证材料等，进行死亡原因分析。</div>
        <div class="pathology_title">
            器官组织法医病理学检验与诊断
        </div>
        <div class="space_content" style="margin-bottom:2rem;">
            通过对人体器官/组织切片进行大体检验和（或）显微组织病理学检验，依据法医病理学专业知识分析、判断，作出法医病理学诊断意见。
        </div>
        <div class="pathology_title">
            死亡方式判断
        </div>
        <div class="space_content" style="margin-bottom:2rem;">
            通过案情调查、现场勘验、尸体检验及相关实验室检验/检测等资料综合分析，判断死者的死亡方式是自然死亡还是他杀、自杀、意外死亡，或者死亡方式不确定。
        </div>
        <div class="pathology_title">
            死亡时间推断
        </div>
        <div class="space_content" style="margin-bottom:2rem;">
            依据尸体现象及其变化规律推断死亡时间；依据胃、肠内容物的量和消化程度推断死亡距最后一次用餐的经历时间；利用生物化学方法，检测体液内化学物质或大分子物质浓度变化等推断死亡时间；利用光谱学、基因组学等技术推断死亡时间；依据法医昆虫学嗜尸性昆虫的发育周期及其演替规律推断死亡时间等。
        </div>
        <div class="pathology_title">
            损伤时间推断
        </div>
        <div class="space_content" style="margin-bottom:2rem;">
            在鉴别生前伤与死后伤的基础上，通过对损伤组织的大体观察和镜下组织病理学检查，依据生前损伤组织修复、愈合、炎症反应等形态学改变，对损伤时间进行推断；利用免疫组织化学和分子生物学等技术，依据生前损伤组织大分子活性物质变化规律等，对伤后存活时间进行推断。
        </div>
        <div class="pathology_title">
            致伤物推断
        </div>
        <div class="space_content" style="margin-bottom:2rem;">
            依据人体损伤形态特征、微量物证及DNA分型检验结果等，结合案情、现场勘验及可疑致伤物特征，对致伤物的类型、大小、质地、重量及作用面形状等进行分析，推断致伤物。
        </div>
        <div class="pathology_title">
            成伤机制分析
        </div>
        <div class="space_content" style="margin-bottom:2rem;">
            依据人体损伤的形态、大小、方向、分布等损伤特征，结合案情、现场勘验及可疑致伤物特征，对损伤是如何形成的进行分析、判断。
        </div>
        <div class="pathology_title">
            医疗损害鉴定
        </div>
        <div class="space_content" style="margin-bottom:2rem;">
            对涉及病理诊断和/或死亡后果等情形的医疗纠纷案件进行鉴定。判断诊疗行为有无过错；诊疗行为与死者死亡后果之间是否存在因果关系以及过错原因力大小等。
        </div>
        <div class="pathology_title">
            与死亡原因相关的其他法医病理鉴定
        </div>
        <div class="space_content" style="margin-bottom:2rem;">
            包括但不限于组织切片特殊染色、尸体影像学检查、组织器官硅藻检验、尸体骨骼的性别和年龄推断等。
        </div>
        <ConnectBtn></ConnectBtn>
    </div>
</template>
<script>
import mixin from "./mixin.js"
export default {
  mixins:[mixin],
}
</script>